<template>
  <div class="dictionary">
    <div class="title">
      <span>系统设置 / </span> <span class="black">量表分类</span>
    </div>
    <el-tabs type="border-card" class="tabs_div" v-model="name" @tab-click="handleClick">
      <el-tab-pane label="家长专栏" name="parent">
        <parent ref="parent"></parent>
      </el-tab-pane>
      <el-tab-pane label="学生专栏" name="student">
        <student ref="student"></student>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import parent from "./parent";
  import student from "./student";
  export default {
    components: {
      parent,
      student,
    },
    data() {
      return {
        name: "parent",
        nameList: ["parent", "student"]
      };
    },
    computed: {
      poewrMenuIdList() {
        return this.$store.state.poewrMenuIdList ? this.$store.state.poewrMenuIdList : []
      },
    },
    methods: {
      handleClick(tab, event) {
        this.nameList.find(item => {
          if (item != tab.name) {
            this.$refs[tab.name].searchForm.typeName = "";
          }
        })
        this.$refs[tab.name].getList();
      },
    },
  };
</script>

<style lang="less" scoped>
  .dictionary {
    /deep/.el-tabs__item {
      width: 152px;
      text-align: center;
    }
  }
</style>